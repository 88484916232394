.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.spinner-overlay {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  /* cursor: pointer; Add a pointer on hover */
}
#spinner-text {
  position: absolute;
  top: 50%;
  left: 50%;
  /* font-size: 50px; */
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.congrats {
  text-align: center;
  width: 100%;
  display: flex;
  height: 100%;
  align-content: center;
  justify-content: center;
  align-items: center;
}
code {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
body {
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
strong {
  font-weight: 600 !important;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
span,
a {
  font-weight: 500 !important;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}
small,
p {
  font-weight: 400 !important;
  font-family: "Muli", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
}

@keyframes confetti-slow {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(25px, 105vh, 0) rotateX(360deg) rotateY(180deg);
  }
}
@keyframes confetti-medium {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(100px, 105vh, 0) rotateX(100deg) rotateY(360deg);
  }
}
@keyframes confetti-fast {
  0% {
    transform: translate3d(0, 0, 0) rotateX(0) rotateY(0);
  }
  100% {
    transform: translate3d(-50px, 105vh, 0) rotateX(10deg) rotateY(250deg);
  }
}
.container {
  width: 100vw;
  height: 75vh;
  background: #fff;
}
.confetti-container {
  perspective: 700px;
  position: absolute;
  overflow: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.confetti {
  position: absolute;
  z-index: 1;
  top: -10px;
  border-radius: 0%;
}
.confetti--animation-slow {
  animation: confetti-slow 2.25s linear 1 forwards;
}
.confetti--animation-medium {
  animation: confetti-medium 1.75s linear 1 forwards;
}
.confetti--animation-fast {
  animation: confetti-fast 1.25s linear 1 forwards;
}
/* Checkmark */
.checkmark-circle {
  width: 140px;
  height: 140px;
  position: relative;
  display: inline-block;
  vertical-align: top;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.checkmark-circle .background {
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: #00c09d;
  position: absolute;
}
.checkmark-circle .checkmark {
  border-radius: 5px;
}
.checkmark-circle .checkmark.draw:after {
  -webkit-animation-delay: 100ms;
  -moz-animation-delay: 100ms;
  animation-delay: 100ms;
  -webkit-animation-duration: 3s;
  -moz-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: ease;
  -moz-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-animation-name: checkmark;
  -moz-animation-name: checkmark;
  animation-name: checkmark;
  -webkit-transform: scaleX(-1) rotate(135deg);
  -moz-transform: scaleX(-1) rotate(135deg);
  -ms-transform: scaleX(-1) rotate(135deg);
  -o-transform: scaleX(-1) rotate(135deg);
  transform: scaleX(-1) rotate(135deg);
  -webkit-animation-fill-mode: forwards;
  -moz-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.checkmark-circle .checkmark:after {
  opacity: 1;
  height: 75px;
  width: 37.5px;
  -webkit-transform-origin: left top;
  -moz-transform-origin: left top;
  -ms-transform-origin: left top;
  -o-transform-origin: left top;
  transform-origin: left top;
  border-right: 15px solid white;
  border-top: 15px solid white;
  border-radius: 2.5px !important;
  content: "";
  left: 25px;
  top: 75px;
  position: absolute;
}
@-webkit-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@-moz-keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: 37.5px;
    opacity: 1;
  }
  40% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
  100% {
    height: 75px;
    width: 37.5px;
    opacity: 1;
  }
}
.submit-btn {
  height: 45px;
  width: 200px;
  font-size: 15px;
  background-color: #00c09d;
  border: 1px solid #00ab8c;
  color: #fff;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(87, 71, 81, 0.2);
  cursor: pointer;
  transition: all 2s ease-out;
  transition: all 0.2s ease-out;
}
.submit-btn:hover {
  background-color: #2ca893;
  transition: all 0.2s ease-out;
}

.skeleton-circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.card.is-loading .image,
.card.is-loading h2,
.card.is-loading p {
  background: #eee;
  background: linear-gradient(110deg, #ececec 8%, #f5f5f5 18%, #ececec 33%);
  border-radius: 5px;
  background-size: 200% 100%;
  animation: 1.5s shine linear infinite;
}
.card.is-loading .image {
  height: 200px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.card.is-loading h2 {
  height: 50px;
}
.card.is-loading p {
  margin-top: 9px;
  height: 50px;
}
@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.product-collapse {
  height: max-content; /* fixed size required */
  transition: all 0.5s;
}
.product-collapse.hide {
  height: 0;
  opacity: 0;
  overflow: hidden;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
.main-error-page {
  min-height: 600px;
  margin: 0px auto;
  width: auto;
  max-width: 560px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.error-title {
  max-width: 529px;
  font-size: 38px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 16px;
}

.error-subtitle {
  max-width: 568px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.31;
  letter-spacing: normal;
  text-align: center;
  color: #4b4b62;
  margin-bottom: 24px;
}
svg {
  /* margin-bottom: 16px; */
  overflow: hidden;
  vertical-align: middle;
}

.full-torradeira {
}
.torradeira {
}

.pao-atras {
  animation: leftright 1s alternate infinite;
  transform-origin: center;
}
.pao-frente {
  animation: leftright 1s 0.3s alternate infinite;
  transform-origin: center;
}

.olho-esq {
  animation: sad 2s alternate infinite;
  transform-origin: center;
}

.olho-dir {
  animation: sad 2s alternate infinite;
  transform-origin: center;
}
.boca {
  animation: sad 2s alternate infinite;
  transform-origin: center;
}
.raios {
  -webkit-animation: flicker-4 4s linear infinite both;
  animation: flicker-4 4s linear infinite both;
}
.tomada {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}
.fio-500 {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}
.fio {
  -webkit-animation: vibrate-1 3s linear infinite both;
  animation: vibrate-1 3s linear infinite both;
}

@keyframes scales {
  from {
    transform: scale(0.98);
  }
  to {
    transform: scale(1);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 14:58:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation flicker-4
 * ----------------------------------------
 */
@-webkit-keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}
@keyframes flicker-4 {
  0%,
  100% {
    opacity: 1;
  }
  31.98% {
    opacity: 1;
  }
  32% {
    opacity: 0;
  }
  32.8% {
    opacity: 0;
  }
  32.82% {
    opacity: 1;
  }
  34.98% {
    opacity: 1;
  }
  35% {
    opacity: 0;
  }
  35.7% {
    opacity: 0;
  }
  35.72% {
    opacity: 1;
  }
  36.98% {
    opacity: 1;
  }
  37% {
    opacity: 0;
  }
  37.6% {
    opacity: 0;
  }
  37.62% {
    opacity: 1;
  }
  67.98% {
    opacity: 1;
  }
  68% {
    opacity: 0;
  }
  68.4% {
    opacity: 0;
  }
  68.42% {
    opacity: 1;
  }
  95.98% {
    opacity: 1;
  }
  96% {
    opacity: 0;
  }
  96.7% {
    opacity: 0;
  }
  96.72% {
    opacity: 1;
  }
  98.98% {
    opacity: 1;
  }
  99% {
    opacity: 0;
  }
  99.6% {
    opacity: 0;
  }
  99.62% {
    opacity: 1;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:17:57
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation vibrate-1
 * ----------------------------------------
 */
@-webkit-keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}
@keyframes vibrate-1 {
  0% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
  20% {
    -webkit-transform: translate(-2px, 2px);
    transform: translate(-2px, 2px);
  }
  40% {
    -webkit-transform: translate(-2px, -2px);
    transform: translate(-2px, -2px);
  }
  60% {
    -webkit-transform: translate(2px, 2px);
    transform: translate(2px, 2px);
  }
  80% {
    -webkit-transform: translate(2px, -2px);
    transform: translate(2px, -2px);
  }
  100% {
    -webkit-transform: translate(0);
    transform: translate(0);
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2020-4-1 15:42:45
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation wobble-ver-right
 * ----------------------------------------
 */
@-webkit-keyframes wobble-ver-right {
  0%,
  100% {
    -webkit-transform: translateY(0) rotate(0);
    transform: translateY(0) rotate(0);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateY(-30px) rotate(6deg);
    transform: translateY(-30px) rotate(6deg);
  }
  30% {
    -webkit-transform: translateY(15px) rotate(-6deg);
    transform: translateY(15px) rotate(-6deg);
  }
  45% {
    -webkit-transform: translateY(-15px) rotate(3.6deg);
    transform: translateY(-15px) rotate(3.6deg);
  }
  60% {
    -webkit-transform: translateY(9px) rotate(-2.4deg);
    transform: translateY(9px) rotate(-2.4deg);
  }
  75% {
    -webkit-transform: translateY(-6px) rotate(1.2deg);
    transform: translateY(-6px) rotate(1.2deg);
  }
}

@keyframes sad {
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  100% {
    transform: rotateX(10deg) rotateY(5deg);
  }
}

@keyframes leftright {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(-15deg);
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.page-fade-in {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  width: 100%;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin: 1 rem;
}
.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  color: #fff;
  text-align: center;
  background-color: #007bff;
  transition: width 0.6s ease;
}
.need-support {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
}
.underline {
  text-decoration: underline;
}

@media (min-width: 998px) {
  .order-status {
    text-align: right;
  }
}

.my-cards-height {
  min-height: 92%;
}
.cards-full-height {
  height: 100%;
}
.btn-link {
  color: #4340c2 !important;
}
.card-content {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.content-body {
  transition: opacity 0.5s ease-in-out;
}

/* Beginning of email list css */

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  background-color: #6967ce;
  border-color: #6967ce;
  color: #444;
  z-index: 2;
}

.list-group-item,
.list-group-item:first-child,
.list-group-item:last-child {
  border-radius: 0px;
  padding: 12px 20px;
}

.list-group-item-heading {
  font-weight: 300;
}
.list-group-item.active > .badge,
.nav-pills > .active > a > .badge {
  color: #3bc0c3;
}
.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: #3bc0c3;
}
.m-t-40 {
  margin-top: 40px !important;
}
.panel {
  border: none;
  box-shadow: none;
}
.panel .panel-body {
  padding: 0px;
}
.panel .panel-body p {
  margin: 0px;
}
.panel .panel-body p + p {
  margin-top: 15px;
}
.panel-default > .panel-heading {
  background-color: #ffffff;
  border-color: #dddddd;
  color: #797979;
}
.panel-heading {
  border-color: #eff2f7;
  font-size: 16px;
  padding: 0;
  padding-bottom: 15px;
}
.panel-title {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: 0;
}
.panel-footer {
  margin: 0px -30px -30px;
  background: #eee;
  border-top: 0px;
}
.panel-group .panel .panel-heading {
  padding-bottom: 0;
  border-bottom: 0;
}
.panel-group .panel {
  margin-bottom: 0;
  border-radius: 0;
}
.m-t-20 {
  margin-top: 20px;
}
.mail-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.btn-default {
  color: #333;
  padding: 6px 12px;
  background-color: #fff;
  border-color: #ccc;
}
.nopadding {
  padding: 0;
}
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.truncate-text {
  display: inline-block;
  max-width: 100%;
}
.bold {
  font-weight: 700 !important;
  color: black;
}

.table-loader {
  width: 100%;
  border-collapse: collapse;
}

.table-loader thead {
  display: none;
}

.table-loader tbody {
  display: block;
}

.table-loader tr {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ddd;
}

.table-loader td {
  flex: 1;
  padding: 10px;
}

.table-loader p,
.table-loader span {
  width: 100%;
  height: 20px;
  background-color: #eee;
  border-radius: 4px;
  position: relative;
}

.table-loader .bold {
  font-weight: bold;
}

.table-loader i.fa {
  margin-right: 5px;
}

.table-loader .mr-1 {
  margin-right: 5px;
}

/* Animation */
@keyframes skeleton-animation {
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: calc(200px + 100%) 0;
  }
}

.table-loader p:before,
.table-loader span:before {
  content: "";
  position: absolute;
  top: 0;
  left: -200px;
  width: 200px;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, #e1e1e1 50%, transparent 100%);
  animation: skeleton-animation 1.5s infinite linear;
}

.table-loader p:after,
.table-loader span:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.table-loader .col-1 p:before {
  width: 30px;
}

.table-loader .text-right span:before {
  width: 70px;
}
.clickable {
  cursor: pointer;
}
.media-meta {
  font-size: 10px;
}
.text-bg-secondary {
  color: #fff !important;
  background-color: RGBA(108, 117, 125, var(--bs-bg-opacity, 1)) !important;
}
.empty-mail-text {
  height: 40px;
  background: #f4f5fa;
  border-radius: 5px;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
}
/* End of email list css */
